import React, { useEffect, useState } from "react";
import Progbar from "./Layouts/Progbar";
import arrowLeft from "../assets/images/back-icon.svg";
import Loader, { Oval } from "react-loader-spinner";
import mainicon from "../assets/images/shop-icon.png";

import { database } from "./firebase";

import {
  ref,
  onValue,
  query,
  get,
  child,
  equalTo,
  orderByKey,
} from "firebase/database";
import { useNavigate, useParams } from "react-router-dom";
import { async } from "@firebase/util";
import axios from "axios";

export default function Test() {
  const params = useParams();
  const navigate = useNavigate();
  const [userID, setUserId] = useState("");
  const src =
    "https://media.istockphoto.com/photos/travel-planning-background-picture-id1309040743?b=1&k=20&m=1309040743&s=170667a&w=0&h=eyIzT1oSW2B5gPMPqgybEseIYIUrY96cxPTE_B0ewVs=";

  const [profilepic, setprofilepic] = useState(null);
  const [name, setname] = useState(null);
  const [username, setusername] = useState(null);
  const [galleryids, setgalleryids] = useState(null);
  const [todisplaygallerys, settodisplaygallerys] = useState([]);

  const [loaderstate, setloaderstate] = useState(true);

  const [link, setlink] = useState(null);
  const [dummy, setDummy] = useState([]);
  useEffect(() => {
    const getUserId = ref(database, "/usernames/" + params.userId);
    get(getUserId)
      .then((snap) => {
        console.log("asdasd", Object.keys(snap.val()));
        getData(Object.keys(snap.val()));
      })
      .catch((err) => {
        console.log("::::", err);
      });
  }, []);
  const getData = async (id) => {
    const starCountRef = ref(database, "/users/" + id);
    onValue(
      starCountRef,
      (snapshot) => {
        const data2 = snapshot.val();
        console.log("data api", data2);
        // console.log("new code test", Object.keys(data2.public.showcasing) );
        if (data2?.public["profile-data"]?.downloadURL) {
          setprofilepic(data2?.public["profile-data"].downloadURL);
        }
        setname(data2?.public["profile-data"]?.name);
        setusername(data2?.public["profile-data"].username);
        getLink(id, data2?.public["profile-data"]?.username);
        if (data2?.public?.showcasing) {
          // getgallaries(Object.keys(data2.public.showcasing));
          // setgalleryids(Object.keys(data2.public.showcasing));
          getgallery2(Object.keys(data2?.public?.showcasing));
        } else {
          setloaderstate(false);
        }
      },
      {
        onlyOnce: true,
      }
    );
  };

  // function getgallaries(gallids) {
  //   const dbRef = ref(database);
  //   get(child(dbRef, "/galleries/" + "4D681AF5-6BF3-42C8-BB8F-C6522EA3D440"))
  //     .then((snapshot) => {
  //       console.log("response", snapshot.val());
  //       if (snapshot.exists()) {
  //         let arr = [];
  //         snapshot.forEach((childSnapshot) => {
  //           const childKey = childSnapshot.key;
  //           const childData = childSnapshot.val();

  //           const equal = (element) => element === childKey;
  //           if (gallids.some(equal)) {
  //             arr.push(childSnapshot.val());
  //           }
  //         });
  //         console.log("array ret", arr);
  //         settodisplaygallerys(arr);
  //         if (arr.length > 0) {
  //         }
  //       } else {
  //         console.log("No data available");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }  
  function getgallery2(gallids) {
    let promisearr = [];
    gallids.map((ent) => {
      const dum = get(ref(database, "galleries/" + ent));
      promisearr.push(
        new Promise((resolve) => {
          dum.then((snapshot) => {
            resolve({ snapshot: snapshot.val(), galId: ent });
          });
        })
      );
    });
    console.log("promise arr", promisearr);
    Promise.all(promisearr).then((results) => {
      setloaderstate(false);
      let resultarr = [];
      results.map((ent) => {
        resultarr.push(ent);
      });
      resultarr.sort(
        (a, b) =>
          b.snapshot.public.galleryInfo.latestPostTime - a.snapshot.public.galleryInfo.latestPostTime
      );

      settodisplaygallerys(resultarr);
    });
  }

  // const getData2 = (ref) => {
  //   return new Promise((resolve, reject) => {
  //     const onError = (error) => reject(error);
  //     const onData = (snap) => resolve(snap.val());

  //     ref.on("value", onData, onError);
  //   });
  // };

  async function getLink(id, name) {
    var data = JSON.stringify({
      dynamicLinkInfo: {
        domainUriPrefix: "https://moodstream.page.link",
        link: `https://moodstream.photos/profileView?username=${name}&uid=${id}`,
        androidInfo: {
          androidPackageName: "photos.moodstream.Moodstream",
        },
        iosInfo: {
          iosBundleId: "com.FernandoMendoza.pokefinder",
          iosAppStoreId: "1436452656",
          // efr: "1",
        },
       /* navigationInfo: {
          enableForcedRedirect: true,
        },*/
      },
    });

    var config = {
      method: "post",
      url: "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAviRlrMvD_4LDEp2hERUploDFRjWNNNyk",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then(function (res) {
        console.log("link stuff", res);
        if (res?.data?.shortLink) {
          setlink(res?.data?.shortLink);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    console.log("to dispaly gall useeffect", todisplaygallerys);
  }, [todisplaygallerys]);

  return (
    <div
      className='profile-container why-finja-main'
      style={{ overflowX: "hidden" }}>
      {loaderstate ? (
        <>
          <div>
            {/* <Loader
              type="TailSpin"
              color="#00BFFF"
              className="full-page-loader"
              height={35}
              width={35}
            /> */}
            <Oval
              height={40}
              width={40}
              color='#000000'
              wrapperStyle={{}}
              className='full-page-loader'
              wrapperClass='full-page-loader'
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor='#000000'
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        </>
      ) : (
        <>
          <div className='top-bar'>
            <span
              className='back-arrow'
            //onClick={backbbtn}
            >
              {" "}
              {/* <img src={arrowLeft} alt="Arrow pointing left" />{" "} */}
            </span>
            {/* <div className="progress-container">
              <a href="https://www.google.com/">Header test</a>
            </div> */}
          </div>
          <div className='top-bar'>
            <span
              className='back-arrow'
            //onClick={backbbtn}
            >
              {" "}
              {/* <img src={arrowLeft} alt="Arrow pointing left" />{" "} */}
            </span>
            <div className='progress-container'>
              {username && (
                <Progbar
                  first={100}
                  second={0}
                  third={0}
                  fourth={0}
                  user={username}
                />
              )}
            </div>
          </div>
          <div className='profilebodyinsta'>
            <div className='instaimgholder-main'>
              {profilepic && (
                <img
                  src={profilepic}
                  alt='Arrow pointing left'
                  className='instaimgholder'
                />
              )}
            </div>

            <div>
            <h2 className='label'>{username}</h2>
            <h2 className='name'>{name && name}</h2>
            </div>
          </div>
          <div>
            <h2 className='text'>Shared Streams</h2>
          </div>

          <div className='rowforalbums'>
            {todisplaygallerys &&
              todisplaygallerys.map((ent, index) => (
                <>
                  {ent.snapshot.public.galleryInfo.postImgURL && (
                    <div
                      className='myclass'
                      key={index}
                      onClick={() => {
                        if (ent.snapshot.public) {
                          navigate("/album", {
                            state: {
                              ent: ent.snapshot,
                              galId: ent.galId,
                              profilepic,
                              name,
                              username,
                              userid: params.userId,
                              link: link,
                            },
                          });
                        }
                      }}>
                      <img
                        className='imgholdergrid'
                        src={ent.snapshot.public.galleryInfo.postImgURL}
                        alt='imgGal'
                      />
                      <div className='top-left'>
                        {ent.snapshot.public.galleryInfo.name}{" "}
                      </div>
                    </div>
                  )}
                </>
              ))}
          </div>
          {link && (
            <div className='footer'>
               <img src="https://firebasestorage.googleapis.com/v0/b/pokefinder-9c71b.appspot.com/o/roundmoodicon.png?alt=media" alt="Icon" className="icon" />
             <p class="footer-paragraph">Join <strong>{username}</strong> on MoodStream and start building your visual diary today!</p>
             <a href={link} className='button'>
             Use the App
            </a>
            </div>
          )}
        </>
      )}
    </div>
  );
}
